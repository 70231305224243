import tab from "../Images/hcbads.jpg";
// 300*300 - Mobile and Yacht column Advertisement
export const Img_URL1 = "https://ayg.s3.us-east-2.amazonaws.com/HCB+(2).gif";

// 300*300 - Ikon Advertisement
export const Img_URL2 = "...";

// Horizontal Advertisement
export const Img_URL3 =
  "https://ayg.s3.us-east-2.amazonaws.com/Explore+Travel.gif";

// 300*600 - Mobile and Yacht-details page Advertisement
export const Img_URL4 = "https://ayg.s3.us-east-2.amazonaws.com/ad3.gif";

// Tab Advertisement
export const Img_URL5 = { tab };
